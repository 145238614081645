@import "_bootstrap-sass-grid";
@import "./libs/owl.carousel.min.css";
@import "./libs/bootstrap.min.css";
@import "./libs/magnificPopup.css";
@import "./libs/animate.min.css";
@import "./libs/selectric";
@import "./libs/bootstrap-slider.min.css";
@import "./libs/jssocials-theme-flat.css";
@import "./libs/custom.css";



$colorPrimary: #177bbb;
$colorWarning: #fcbf1a;
$colorDanger: #dd1e32;
$colorSucess:  #179877;
$color: #17a6e1;
$color2: #f28120;


$breakpoint-small-desktop:1024px;
$breakpoint-tablet:910px;
$breakpoint-mobile:760px;

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v5/01M8UnHoM7MaVdT1TD1pbjG_owQUHpNX9rkRdZ1lhPA.woff) format('woff');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200B-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v5/MEXWGbc1EWORtBwy9fFeUjG_owQUHpNX9rkRdZ1lhPA.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v5/D2gN9uyZ9Ucx1fJ1Fhb7s73hpw3pgy2gAi-Ip7WPMi0.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v5/v_TZr23Jez7Kuo-_4vuRvVKPGs1ZzpMvnHX-7fPOuAc.woff) format('woff');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200B-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v5/0hyMx537ApGjkDVOSI6XElKPGs1ZzpMvnHX-7fPOuAc.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v5/ePBp1XdWMQqYWkm0HYfk2gLUuEpTyoUstqEm5AMlJo4.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v5/Aul8cxPpbm96Ali7smyVfDG_owQUHpNX9rkRdZ1lhPA.woff) format('woff');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200B-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v5/aDjpMND83pDErGXlVEr-STG_owQUHpNX9rkRdZ1lhPA.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v5/57TQ-anwthzkETEIO4jESL3hpw3pgy2gAi-Ip7WPMi0.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}
@font-face {
  font-family: 'iconpack';
  src:  url('../icons/iconpack.eot?hc48ms');
  src:  url('../icons/iconpack.eot?hc48ms#iefix') format('embedded-opentype'),
    url('../icons/iconpack.ttf?hc48ms') format('truetype'),
    url('../icons/iconpack.woff?hc48ms') format('woff'),
    url('../icons/iconpack.svg?hc48ms#iconpack') format('svg');
  font-weight: normal;
  font-style: normal;
}

html,body{
    overflow-x: hidden;
}
body{
	background-color: #303030;
	color: #000000;
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	line-height:22px;
    font-weight: 500;
    min-height: 100vh;
    /* START global options */
    .check{
        line-height: 35px;
        font-size: 14px;
        .icheckbox_minimal{
            margin-right: 15px;
        }
    }
    a:hover{
        text-decoration: none;
    }
    .text-left{
        text-align: left;
    }
    .text-right{
        text-align: right;
    }
    .text-center{
        text-align: center;
    }
    .bg-white{
        background-color: white;
    }
    .bg-yellou{
        background-color: #ffe400;
        color: black;
    }
    .text-color{
        color: $color;
    }
    .text-color2{
        color: $color2;
    }
    .bold{
        font-weight: bold;
    }
    .noPadding{
        padding: 0px !important;
    }
    .noPaddingLeft{
        padding-left: 0px !important;
    }
    .hidden{
        display: none;
    }
    .visibles-md{
        display: block;
    }
    hr{
        border-bottom: 1px solid #b3b2b2;
    }
    h1,h2{
        font-weight: bold;
        font-size:38px;
        background-image: url("../images/h1Bg.jpg");
        background-repeat: no-repeat;
        background-position: bottom left;
        padding-bottom: 32px;
        background-size: 20% 3px;
        margin-bottom: 60px;
        line-height:42px;
    }
    .text-center h1,.text-center h2{
        background-position: bottom center;
    }
    img.img-responsive{
        width: 100%;
        margin: auto;
    }
    .text-right img{
        margin-left: auto;
        margin-right: 0px;
    }
    ul.default{
        margin: 0px;
        padding: 0px;
        list-style: none;
        li{
            margin: 0px;
            padding: 0px;
        }
    }
    ul.inText{
        li{
            margin-bottom: 20px;
            &::before{
                font-family: 'iconpack' !important;
                content: "\e900";
                color: #17a6e1;
                font-size: 12px;
                padding-right: 15px;
            }
        }
    }
    .contact-blue-box{
        padding: 15px;
        text-align: center;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ecf7ff+0,c1e5ff+100 */
        background: #ecf7ff; /* Old browsers */
        background: -moz-linear-gradient(left, #ecf7ff 0%, #c1e5ff 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #ecf7ff 0%,#c1e5ff 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #ecf7ff 0%,#c1e5ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ecf7ff', endColorstr='#c1e5ff',GradientType=1 ); /* IE6-9 */
        font-size: 16px;
        border-radius: 6px;
        margin-top: 15px;
        a{
            color: #18a5df;
            border-bottom: 1px solid #18a5df;
            padding-bottom: 5px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .contact{
        strong.c{
            display: block;
            float: left;
            min-width: 150px;
        }
    }

    #car-scroll{
        top: 0px;
        background-color: #18a5df;
        -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
        box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
        position: fixed;
        z-index: 999;
        width: 100%;
        display: none;
        line-height: 80px;
        &.active{
            display: block;
        }

        big{
            font-size: 28px;
            display: block;
            color: white;
            font-weight: bold;
        }
        span.price{
            padding-top: 5px;
            display: block;
            font-size: 18px;
        }
        .pull-right{
            background-color: #f28120;
            color: white;
            line-height: 80px;
            padding-left: 10px;
            padding-right: 10px;
            font-size: 22px;
        }
    }
    .timeline {
    	padding: 20px 0px;
    	width: 80%;
    	margin-left: 20px;
    	margin-bottom: 40px;
        * {
        	pading: 0;
        	margin: 0;
        	font-family: "Lato", sans-serif;
        	position: relative;
        }
        p{
            font-size: 13px;
        	line-height: 20px;
        	text-align: right;
        }
        &:before {
        	content: "";
        	position: absolute;
        	top: 40px;
        	left: 18px;
        	width: 3px;
        	height: calc(100% - 80px);
        	background: #008bc6;
        }
        .column {
        	margin: 40px 40px 40px 120px;
            margin-left: 20px;
            margin-bottom: 80px;
            .title {
                h1 {
                	font-size: 80px;
                	color: rgba(0,0,0,0.1);
                	font-family: serif;
                	letter-spacing: 3px;
                    background: none;
                    &:before {
                    	content: "";
                    	position: absolute;
                    	left: -45px;
                    	top: 86px;
                    	width: 20px;
                    	height: 20px;
                        border-radius: 40px;
                    	background: #fff;
                    	border: 3px solid #008bc6;
                    }
                }
                h2 {
                    background: none;
                	margin-top: -60px;
                	font-size: 33px;
                }
            }
            .description{
                border-left: 1px solid #000;
                p {
                	font-size: 15px;
                	line-height: 20px;
                	margin-left: 20px;
                	margin-top: 10px;
                    text-align: left;
                }
            }
        }
    }
    .blueText{
        color: #337ab7;
    }

    ul.noMargin{
        webkit-padding-start: 20px;
        margin: 0px;
        margin-left: 30px;
        padding-left: 0px;
    }
    ul.quote{
        margin: 0px;
        padding: 0px;
        li{
            border-bottom: 1px solid #eceff7;
            padding-bottom: 15px;
            list-style: none;
            &:hover{
                cursor: pointer;
            }
            h3{
                color: #f58220;
            }
            h3::after{
                content: "\e900";
                font-family: iconpack!important;
                speak: none;
                font-style: normal;
                font-weight: 400;
                font-variant: normal;
                text-transform: none;
                display: block;
                position: absolute;
                right: 0px;
                color: #f58220;
                margin-top: -25px;
            }

            p{
                font-size: 15px;
                display: none;
                strong{
                    display: block;
                    color: #17a6e1;
                }
            }
            &.active{
                p{
                    display: block;
                }
                h3::after{
                    -ms-transform: rotate(90deg); /* IE 9 */
                   -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
                   transform: rotate(90deg);
                }
            }
        }
    }
    .whiteBg{
        background-color: #ffffff;
        padding-top: 20px;
        padding-bottom: 40px;
        .contact-boxs{
            padding-top: 15px;
            padding-bottom: 15px;
            background-color: #f28120;
            color: white;
            font-size: 17px;
            line-height: 30px;
            a{
                color: white;

            }

        }
        ul.list{
            li{
                padding: 5px;
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 15px;
            }
            &:nth-child(odd) {
                background: #e9e9e9;
            }
            &::after {
                display: block;
                content: "";
                clear: both;
            }
        }
    }
    .greyBg{
        background-color: #e9e9e9;
        padding-top: 20px;
        padding-bottom: 40px;
    }
    .btn-padding{
        padding-top: 30px;
    }
    .btn{
        background-color: #17a6e1;
        color: white;
        text-decoration: none;
        line-height: 35px;
        border-bottom: 6px solid #008bc6;
        border-radius: 0px;
        font-weight: bold;
        padding-left:30px;
        padding-right: 30px;
        font-size: 22px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .btns{
        padding-bottom: 40px;
        a{
            cursor: pointer;
            color: black;
            border: 1px solid #a7a7a7;
            display: inline-block;
            margin-left: 5px;
            margin-right: 5px;
            padding: 20px;
            margin-bottom: 15px;
            &.active,&:hover{
                color: white;
                border:1px solid #17a6e1;
                background-color: #17a6e1
            }
            &.active{
                font-weight: bold;
            }
        }
    }
    a.products{
        cursor: pointer;
        display: block;
        background-color: white;
        margin-bottom: 20px;
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.13);
        -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.13);
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.13);
        span.img,span.pr{
            display: block;
            padding: 0px;
            overflow: hidden;
            position: relative;
        }
        strong.tag{
                position: absolute;
                right:0px;
                margin-top: 30px;
                margin-right: -30px;
                -ms-transform: rotate(45deg); /* IE 9 */
                -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
                transform: rotate(45deg);
                width: 150px !important;
                text-align: center;
                font-size: 14px;
                line-height: 14px;
                padding-top: 5px;
                padding-bottom: 5px;
                height: auto;
                &.novinka{
                    color:white;
                    background: #bfd255; /* Old browsers */
                    background: -moz-linear-gradient(left, #bfd255 0%, #72aa00 99%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(left, #bfd255 0%,#72aa00 99%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to right, #bfd255 0%,#72aa00 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bfd255', endColorstr='#72aa00',GradientType=1 ); /* IE6-9 */
                }
                &.prodano{
                    color:white;
                    background: #a90329; /* Old browsers */
                    background: -moz-linear-gradient(left, #a90329 0%, #8f0222 44%, #6d0019 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(left, #a90329 0%,#8f0222 44%,#6d0019 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to right, #a90329 0%,#8f0222 44%,#6d0019 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a90329', endColorstr='#6d0019',GradientType=1 );
                }
        }
        .desc{
            display: block;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 20px;
            strong{
                font-size: 25px;
                line-height: 27px;
                color: black;
                display: block;
                padding-top: 10px;
                height: 59px;
                overflow: hidden;
                width: 100% !important;
            }
            .icons{
                padding-left: 0px;
                padding-right: 15px;
                display: block;
                width: 100% !important;
                padding-top: 10px;
                i{
                    display: block;
                    font-size: 28px;
                    margin-bottom: 10px;
                }
                span{
                    float: left;
                    text-align: center;
                    color: black;
                    font-size: 20px;
                    display: block;
                    padding-right: 30px;
                }
            }
        }
        .price,.btns{
            color: white;
        }
        .price{
            background-color: #f28120;
            font-size: 20px;
            display: block;
            width: 100%;
            padding: 10px;
            display: flex;
            align-items: center;
            span{
                color: black;
                font-size: 15px;
                display: block;
                margin-left: auto;
                margin-right: auto;
                small{
                    font-size: 20px;
                    color: white;
                    display: block;
                }
            }
        }
        .btns{
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1aa2dd+0,00ccff+100 */
            background: rgb(26,162,221); /* Old browsers */
            background: -moz-linear-gradient(left, rgba(26,162,221,1) 0%, rgba(0,204,255,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(26,162,221,1) 0%,rgba(0,204,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(26,162,221,1) 0%,rgba(0,204,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1aa2dd', endColorstr='#00ccff',GradientType=1 ); /* IE6-9 */
            font-size: 16px;
            text-align: center;
            width: 100%;
            display: flex;
            align-items: center;
            padding-bottom: 0px;
            span{
                color: white;
                font-size: 20px;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .h-2{
            height: 95px;
        }
    }
    a.product{
        display: block;
        width: 100%;
        background-color: white;
        margin-bottom: 25px;
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.13);
        -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.13);
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.13);
        position: relative;
        overflow: hidden;
        cursor: pointer;
        strong,.icons{
            padding-left: 15px;
            padding-right: 15px;
            display: block;
            width: 100% !important;
            i{
                display: block;
                font-size: 20px;
                margin-bottom: 10px;
            }
        }
        strong{
            font-size: 19px;
            line-height: 24px;
            color: black;
            display: block;
            padding-top: 10px;
            height: 55px;
            text-align: center;
            overflow: hidden;
        }
        strong.tag{
                position: absolute;
                right:0px;
                margin-top: 30px;
                margin-right: -30px;
                -ms-transform: rotate(45deg); /* IE 9 */
                -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
                transform: rotate(45deg);
                width: 150px !important;
                text-align: center;
                font-size: 14px;
                line-height: 14px;
                padding-top: 5px;
                padding-bottom: 5px;
                height: auto;
                &.novinka{
                    color:white;
                    background: #bfd255; /* Old browsers */
                    background: -moz-linear-gradient(left, #bfd255 0%, #72aa00 99%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(left, #bfd255 0%,#72aa00 99%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to right, #bfd255 0%,#72aa00 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bfd255', endColorstr='#72aa00',GradientType=1 ); /* IE6-9 */
                }
                &.prodano{
                    color:white;
                    background: #a90329; /* Old browsers */
                    background: -moz-linear-gradient(left, #a90329 0%, #8f0222 44%, #6d0019 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(left, #a90329 0%,#8f0222 44%,#6d0019 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to right, #a90329 0%,#8f0222 44%,#6d0019 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a90329', endColorstr='#6d0019',GradientType=1 );
                }
        }
        .icons{
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 0px;
            padding-right: 0px;
            span{
                float: left;
                width: 20%;
                /* width: (100%/4%)*1%; */
                text-align: center;
                color: black;
                font-size: 11px;
            }
            span:nth-child(4){
                width: 40% !important;
            }
        }
        .price,.btns{
            width: (100%/2%)*1%;
            padding: 10px;
            color: white;
            float: left;
            height: 60px;
            overflow: hidden;

        }
        .price{
            line-height: 20px;
            background-color: #f28120;
            font-size: 14px;
            span{
                color: black;
                font-size: 15px;
                display: block;
            }
        }
        .btns{
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1aa2dd+0,00ccff+100 */
            background: rgb(26,162,221); /* Old browsers */
            background: -moz-linear-gradient(left, rgba(26,162,221,1) 0%, rgba(0,204,255,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(26,162,221,1) 0%,rgba(0,204,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(26,162,221,1) 0%,rgba(0,204,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1aa2dd', endColorstr='#00ccff',GradientType=1 ); /* IE6-9 */
            line-height: 40px;
            font-size: 16px;
            text-align: center;
        }
    }
    #share{
        margin-top: 5px;
        .jssocials-share{
            float: left;
            margin-right: 5px;
            width: 80px;

        }
    }
    i.icon {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'iconpack' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /*
      &.icon-share:before {
        content: "\e91a";
      }
      &.icon-arrow2-left:before {
        content: "\e914";
      }
      &.icon-arrow2-right:before {
        content: "\e915";
      }
      &.icon-phone2:before {
        content: "\e916";
      }
      &.icon-time:before {
        content: "\e917";
      }
      &.icon-zoom:before {
        content: "\e912";
      }
      &.icon-close-browser:before {
        content: "\e918";
      }
      &.icon-bars:before {
        content: "\e905";
      }
      &.icon-facebook-logo-button:before {
        content: "\e904";
      }
      &.icon-arrow-right:before {
        content: "\e900";
      }
      &.icon-calculator:before {
        content: "\e901";
      }
      &.icon-calendar-with-spring-binder-and-date-blocks:before {
        content: "\e902";
      }
      &.icon-certifikat:before {
        content: "\e903";
      }
      &.icon-facebook-ring .path1:before {
        content: "\e906";
        color: rgb(59, 89, 152);
      }
      &.icon-facebook-ring .path2:before {
        content: "\e919";
        margin-left: -1em;
        color: rgb(255, 255, 255);
      }
      &.icon-fuel-station-pump:before {
        content: "\e907";
      }
      &.icon-home:before {
        content: "\e908";
      }
      &.icon-icon:before {
        content: "\e909";
      }
      &.icon-money:before {
        content: "\e90a";
      }
      &.icon-ok-ring:before {
        content: "\e90b";
      }
      &.icon-ok:before {
        content: "\e90c";
      }
      &.icon-phone:before {
        content: "\e90d";
      }
      &.icon-pistons-cross:before {
        content: "\e90e";
      }
      &.icon-pneu:before {
        content: "\e90f";
      }
      &.icon-print:before {
        content: "\e910";
      }
      &.icon-right:before {
        content: "\e911";
      }
      &.icon-splatky:before {
        content: "\e918";
      }
      &.icon-calculator:before {
        content: "\e901";
      }
      */

      &.icon-share:before {
        content: "\e91a";
      }
      &.icon-close-browser:before {
        content: "\e913";
      }
      &.icon-delete:before {
        content: "\e912";
      }
      &.icon-delete2:before {
        content: "\e914";
      }
      &.icon-arrow2-left:before {
        content: "\e914";
      }
      &.icon-arrow2-right:before {
        content: "\e915";
      }
      &.icon-phone2:before {
        content: "\e917";
      }
      &.icon-time:before {
        content: "\e918";
      }
      &.icon-zoom:before {
        content: "\e912";
      }
      &.icon-close-browser2:before {
        content: "\e91b";
      }
      &.icon-delete3:before {
        content: "\e91c";
      }
      &.icon-bars:before {
        content: "\e905";
      }
      &.icon-facebook-logo-button:before {
        content: "\e904";
      }
      &.icon-arrow-right:before {
        content: "\e900";
      }
      &.icon-calculator:before {
        content: "\e901";
      }
      &.icon-calendar-with-spring-binder-and-date-blocks:before {
        content: "\e902";
      }
      &.icon-certifikat:before {
        content: "\e903";
      }
      &.icon-facebook-ring:before {
        content: "\e906";
      }
      &.icon-fuel-station-pump:before {
        content: "\e907";
      }
      &.icon-home:before {
        content: "\e908";
      }
      &.icon-icon:before {
        content: "\e909";
      }
      &.icon-money:before {
        content: "\e90a";
      }
      &.icon-ok-ring:before {
        content: "\e90b";
      }
      &.icon-ok:before {
        content: "\e90c";
      }
      &.icon-phone:before {
        content: "\e90d";
      }
      &.icon-pistons-cross:before {
        content: "\e90e";
      }
      &.icon-pneu:before {
        content: "\e90f";
      }
      &.icon-print:before {
        content: "\e910";
      }
      &.icon-right:before {
        content: "\e911";
      }
      &.icon-splatky:before {
        content: "\e918";
      }
      &.icon-calculator:before {
        content: "\e901";
      }

    }
    br.visibleMobile{
        display: none;
    }
    .blank{
        width: 100%;
        height: 30px;
    }
    .photos{
        .col{
            margin-top: 10px;
            margin-bottom: 10px;
            img{
                width: 100% !important;
                height: auto !important;
            }
        }

    }
    .hidden-input{
        opacity: .3;
        &.active{
            opacity: 1;
        }
    }
    .pagination {
        display: inline-block;
        padding-left: 0;
        margin: 20px 0;
        border-radius: 4px;
        &>li{
            display: inline;
            a,span{
                position: relative;
                float: left;
                padding: 6px 12px;
                margin-left: -1px;
                line-height: 1.42857143;
                color: #7e7e7e;
                font-weight: bold;
                text-decoration: none;
                background-color: #fff;
                border: 1px solid #ddd;
                background: #ffffff; /* Old browsers */
                background: -moz-linear-gradient(top, #ffffff 0%, #f3f3f3 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, #ffffff 0%,#f3f3f3 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, #ffffff 0%,#f3f3f3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f3f3f3',GradientType=0 );
                i{
                    font-size: 12px;
                }
            }
            a.active{
                color: #17a6e1;
            }
            a:hover{
                background: none;
                background-color: #17a6e1;
                color: white;
                text-shadow: 1px 1px black;
                -webkit-box-shadow: inset 0px 0px 15px 0px rgba(0,0,0,0.35);
                -moz-box-shadow: inset 0px 0px 15px 0px rgba(0,0,0,0.35);
                box-shadow: inset 0px 0px 15px 0px rgba(0,0,0,0.35);
            }
            &:first-child>a, &>li:first-child>span{
                margin-left: 0;
                border-top-left-radius: 30px;
                border-bottom-left-radius: 30px;
            }
            &:last-child>a, &>li:last-child>span{
                border-top-right-radius: 30px;
                border-bottom-right-radius: 30px
            }
        }
    }
    .btnsr {
        border-radius: 30px;
        padding: 10px 20px;
        font-weight: 500;
        margin-left: 10px;
        margin-right: 10px;
        text-decoration: none;
        &.btns-red-border {
            border: 1px solid #18a5df;
            color: #18a5df;

            &:hover {
                background-color: #18a5df;
                color: white;
                text-decoration: none;
            }
        }
        &.actived{
            background-color: #18a5df;
            color: white;
        }

        i {
            margin-right: 10px;
        }
    }
    .marBottom{
        padding-bottom: 15px;
        a{
            display: block;
            width: 100%;
        }
    }
    .responsive-table{
        width: 100%;
        overflow-x: auto;
        table{
            width: 100%;
            max-width: 100%;
            overflow-x: auto;
            margin-bottom: 1rem;
            border-collapse: collapse;
            tbody{
                tr:nth-child(even){
                    td{
                        background-color:rgba(0,0,0,.05);
                    }

                }
            }
            tr:hover{
                position: relative;
                z-index: 990;
                -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
                -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
                box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
            }
            tr{
                width: 100%;
                vertical-align: inherit;
                th,td{
                    width: auto;
                    padding: .75rem;
                    display: table-cell;
                    line-height: 1.5;
                    -webkit-text-size-adjust: 100%;
                    -ms-text-size-adjust: 100%;
                    -ms-overflow-style: scrollbar;
                    -webkit-tap-highlight-color: transparent;
                    text-align: center;
                }
                td{
                    white-space: nowrap;
                    span{
                        padding-left: 20px;
                    }
                    i{
                        color:#18a5df;
                        &.icon-cross{
                            color: black;
                            opacity: .3;
                        }
                    }
                }
                th{
                    background-color: #18a5df;
                    color:white;
                    small{
                        font-weight: 100;
                        font-size: 18px;
                    }

                }
                td:first-child{
                    text-align: left;
                }
            }
        }
    }
    .owl-cars{
        padding-left: 40px;
        padding-right: 40px;
        .owl-nav{
            .owl-prev,.owl-next{
                position: absolute;
                font-size: 40px;
                text-align: center;
                width: 80px;
                height: 340px;
                margin-top: -410px;
                line-height: 340px;
                color: #7e7e7e;
            }
            .owl-prev{
                left: -30px;
            }
            .owl-next{
                right: -30px;
            }
        }
    }
    /* START breadcrumb options */
    #breadcrumb{
        color: white;
        background-color: #008bc6;
        padding-top: 10px;
        padding-bottom: 10px;
        ul{
            li{
                font-weight: bold;
                display: inline-block;
                font-size: 13px;
                span{
                    border-bottom: 1px solid #008bc6;
                }
                &::after{
                    content: "\e915";
                    font-family: 'iconpack' !important;
                    color: white;
                    padding-left: 10px;
                    padding-right: 10px;
                    font-size: 10px;
                }
                &:last-child::after{
                    content: none;
                }
                &:hover span{
                    border-bottom: 1px solid white;
                }
                a{
                    color: white;
                    font-weight: 100;
                }
            }
        }
    }
    /* END breadcrumb options */
    /* START headline options */
    #headline{
        background-color: #e9e9e9;
        line-height: 42px;
        ul{
            li{
                display: inline-block;
                padding-left: 10px;
                padding-right: 10px;
                font-size: 13px;
                border-right: 1px solid #e1e1e1;
                &:first-child{
                    padding-left: 0px;
                }
                a.btns-top{
                    background-color: #18a5df;
                    color: white;
                    padding-left: 4px;
                    padding-right: 4px;
                    font-weight:bold;
                    border-radius: 4px;
                    padding-top: 2px;
                    padding-bottom: 2px;
                }

            }
        }
        a.pull-right{
            color: #3b5998;
            i{
                color: #3b5998 !important;
            }
        }
    }
    /* END headline options */
    /* START head options */
    #head{
        background-color: white;
        padding-top: 15px;
        padding-bottom: 15px;
        a.logo{
            img{
                max-width: 220px;
            }
        }
        .pull-right{
            padding-top: 20px;
        }
        ul,img{
            float:left;
        }
        i.icon{
            display: none;
        }
        ul{
            margin-left: 15px;
            margin-top: 7px;
            li{
                display: inline-block;
                padding-left: 20px;
                a{
                    font-size: 16px;
                    color: black;
                    padding-bottom: 2px;
                    border-bottom: 1px white #17a6e0;
                    display: block;
                }
                &.active a,&:hover a{
                    border-bottom: 1px solid #17a6e0;
                }
                &.active{
                    font-weight: bold;
                }
                &.dropdown{
                    padding-bottom: 10px;
                }
                &.dropdown:hover a{
                    border-bottom: none;
                }
                &.dropdown:hover::after{
                    content: "";
                    top: 23px;
                    left: 30%;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 10px solid rgba(57, 70, 78, .15);
                    border-style: none double solid;
                    display: block;
                    position: absolute;
                    right: 10px;
                    vertical-align: middle;
                    width: 0;
                    height: 0;
                    z-index: 998;
                }
                &.dropdown:hover::before{
                    top: 25px;
                    left: 30%;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 10px solid #fff;
                    border-style: none double solid;
                    display: block;
                    z-index: 999;
                    position: absolute;
                    vertical-align: middle;
                    content: "";
                    width: 0;
                    height: 0;
                }
                ul.dropdown{
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 1000;
                    text-align: left;
                    min-width: 230px;
                    padding: 5px 0;
                    margin: 2px 0 0;
                    list-style: none;
                    font-size: 14px;
                    background-color: #fff;
                    border: 1px solid #ccc;
                    border: 1px solid rgba(0, 0, 0, .15);
                    border-radius: 4px;
                    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
                    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
                    background-clip: padding-box;
                    margin-top: 0px;
                    display: none;
                    z-index: 900;
                    li{
                        padding-top: 5px;
                        padding-bottom: 5px;
                        display: block;
                    }
                    a{
                        font-size: 14px;
                        border-bottom: none;
                        &:hover{
                        }
                    }
                }
                &.dropdown:hover ul{
                    display: block;
                }
            }
        }
    }
    /* END head options */
    #slider{
        overflow: hidden;
        background-color: white;
    }
    /* STAR headline sub options */
    #headlinesub{
        background-color: #18a5df;
        color: white;
        line-height: 90px;
        big{
            font-weight: bold;
            font-size: 34px;
            line-height: 38px;
            padding-top: 5px;
            display: block;
            height: 100px;
            display: flex;
            justify-content: left; /* align horizontal */
            align-items: center; /* align vertical */
            small{
                font-size: 28px;
                strong{
                    color: #f58220;
                }
            }
        }
        span{
            font-weight: 100;
            font-size: 24px;
        }
        a.pull-right.link{
            background-color: #f58220;
            color: white;
            border-radius: 6px;
            line-height: 40px;
            padding-left: 10px;
            padding-right: 10px;
            margin-top: 25px;
        }
        &.detail-car{
            line-height: normal;
            big{
                font-size: 28px;
                line-height: 100px;
            }
            big.detail{
                line-height: 26px;
                font-size: 24px;
                display: block;
                height: 100px;
                display: flex;
                justify-content: left; /* align horizontal */
                align-items: center; /* align vertical */
            }
            .btn-group{
                width: 100%;
                padding-top: 15px;
                padding-bottom: 10px;
                a{
                    color: white;
                    font-size: 14px;
                    text-transform: uppercase;
                    &.disabled{
                        color: #008bc6;
                    }
                    i{
                        margin-left: 5px;
                        margin-right: 5px;
                    }
                }
            }
            .btn-social{
                width: 260px;
                margin-left: auto;
                margin-right: 0px;
                font-size: 13px;
                text-align: right;
                #share{
                    float: left;
                }
                a{
                    float: left;
                    padding-top: 3px;
                    font-size: 13px;
                    color: white;
                    text-transform: uppercase;
                    border-bottom: 1px solid white;
                    &:hover{
                        border-bottom: none;
                    }
                }
            }
        }
    }
    /* END headlinesub options */
    /* START filter options */
    #filter{
        background-color: #f58220;
        color: white;
        padding-top: 15px;
        padding-bottom: 15px;
        button.btn{
            margin-top: 26px;
        }
        &.subpage{
            background-image: url("../images/filterBg.jpg");
            background-repeat: no-repeat;
            background-position: center;
            background-size:cover;
            button,a.btn2{
                min-width: 130px;
                line-height: 40px;
                margin-top: 0px;
                text-align: center;
                margin-bottom: 4px;
                i{
                    margin-right: 5px;
                }
            }
        }
        a{
            color: white;
            span{
                font-weight: 100;
                padding-bottom: 3px;
                border-bottom: 1px solid white;
            }
        }
        .hiddens{
            display: none;
            &.active{
                display: block !important;
            }
        }
        .select{
            margin-bottom: 25px;
            label{
                display: block;
                color: white;
                margin-bottom: 5px;

            }
        }
        .checkbox{
            label{
                line-height: 40px;
                margin-right: 10px;
                display: inline-block;
                font-size: 15px;
                &:last-child {
                    margin-right: 0px !important;
                }
            }
        }
        a.btn2{
            border: 2px solid white;
            line-height: 64px;
            display: inline-block;
            padding-left: 10px;
            padding-right: 10px;
            i{
                font-size: 14px;
            }
            &:hover{
                background-color: white;
                color: #f58220;
            }
        }
    }
    /* END filter options */
    /* START categories */
    #categories{
        &.whiteBg{
            padding: 0px;
            a{

                display: block;
                width: 100%;
                padding-bottom: 40px;
                strong{
                    color: black;
                    font-size: 24px;
                }

            }
            div.text-center{
                border-right: 1px solid #dadada;
                &:first-child{
                    border-left: 1px solid #dadada;
                }
            }
        }
    }
    /* END categories*/
    /* START detail car */
    #detail-car{
        background-color: #ffffff;
        .row:first-child {
            overflow-y: hidden;
        }
        .photo{
            strong.tag{
                    position: absolute;
                    right:0px;
                    margin-top: 80px;
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-right: -70px;
                    -ms-transform: rotate(45deg); /* IE 9 */
                    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
                    transform: rotate(45deg);
                    min-width: 360px;
                    text-align: center;
                    font-size: 30px;
                    line-height: 40px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    height: auto;
                    &.novinka{
                        color:white;
                        background: #bfd255; /* Old browsers */
                        background: -moz-linear-gradient(left, #bfd255 0%, #72aa00 99%); /* FF3.6-15 */
                        background: -webkit-linear-gradient(left, #bfd255 0%,#72aa00 99%); /* Chrome10-25,Safari5.1-6 */
                        background: linear-gradient(to right, #bfd255 0%,#72aa00 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bfd255', endColorstr='#72aa00',GradientType=1 ); /* IE6-9 */
                    }
                    &.prodano{
                        color:white;
                        background: #a90329; /* Old browsers */
                        background: -moz-linear-gradient(left, #a90329 0%, #8f0222 44%, #6d0019 100%); /* FF3.6-15 */
                        background: -webkit-linear-gradient(left, #a90329 0%,#8f0222 44%,#6d0019 100%); /* Chrome10-25,Safari5.1-6 */
                        background: linear-gradient(to right, #a90329 0%,#8f0222 44%,#6d0019 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a90329', endColorstr='#6d0019',GradientType=1 );
                    }
            }
        }
        .price{
            background-color: #f28120;
            font-size: 22px;
            display: block;
            padding: 10px;
            color: white;
            line-height: 40px;
            padding-left: 30px;
            height: 140px;
            span{
                display: block;
                color: black;
                font-size: 16px;
                font-weight: bold;
            }
            big{
                font-weight: bold;
                font-size: 26px;
                display: block;
                color: white;
            }
        }
        .bt{
            font-weight: bold;
            font-size: 14px;
            text-align: center;
            cursor: pointer;
            a{
                padding-top: 46px;
                padding-bottom: 50px;
                color: white;
                display: block;
                white-space: nowrap;
                i{
                    font-size: 40px;
                    margin-right: 10px;
                }
                i,span{
                    display: inline-block;
                }
                span{
                    text-align: left;
                }
            }
            &.green{
                background-color: #84ce2c;
            }
            &.pink{
                background-color: #8f4ec6;
            }
        }
        .list{
            display: none;
            &.active{
                display: block;
            }
            .form-group{
                input{
                    width: 100%;
                    margin-bottom: 15px;
                    padding: 5px;
                    font-size: 14px;
                    color: #000;
                }
                textarea{
                    width: 100%;
                    border: none;
                    padding: 5px;
                    font-size: 14px;
                    color: #000;
                    height: 100px;
                }
                p{
                    margin: 0px;
                    padding: 0px;
                    padding-bottom: 15px;
                    ul{
                        list-style: none;
                        margin: 0px;
                        padding: 0px;
                        li{
                            line-height: 12px !important;
                            padding: 0px;
                            margin: 0px;
                            background: none !important;
                            font-size: 13px;
                        }
                    }
                }
            }

            .submit{
                font-size: 14px;
                color: white;
            }
            hr{
                padding-top: 5px;
                padding-bottom: 5px;
                margin-top: 5px;
                margin-bottom: 15px;
                border: none;
                border-bottom: 1px dashed rgba(255,255,255,.3);
            }
            p{
                font-size: 15px;
                color: white;
            }
            &#kalkulacka-splatek{
                overflow-y: auto;
                overflow-x: hidden;
                background-color: #8f4ec6;
                .checkbox{
                    padding-bottom: 15px;
                    line-height: 18px;
                    font-size: 14px;
                    label{
                        color: white;
                        font-size: 14px;
                    }
                    .checkbox-input{
                        margin-top: 5px;
                        margin-right: 15px;
                    }
                }
                .rangeInput{
                    margin-bottom: 20px;
                    color: white;
                    font-size: 15px;
                    span{
                        span{
                            font-weight: bold;
                        }
                    }
                }
                big{
                    line-height: 30px;
                    color: white;
                    font-size: 18px;
                    strong{
                        font-size: 25px;
                    }
                }
                button{
                    width: 100%;
                    text-align: center;
                    background-color: #84ce2c;
                    border-bottom: 6px solid #5c9617;
                }
                .slider.slider-horizontal{
                    width: 100%;
                    .slider-selection{
                        background: none;
                        background-color: #84ce2c;
                        border-radius: 20px;
                    }
                    .slider-track{
                        height: 20px;
                        border-radius: 20px;
                    }
                    .slider-handle{
                        width: 30px;
                        height: 30px;
                        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8fc400+0,559c00+100 */
                        background: #8fc400; /* Old browsers */
                        background: -moz-linear-gradient(-45deg, #8fc400 0%, #559c00 100%); /* FF3.6-15 */
                        background: -webkit-linear-gradient(-45deg, #8fc400 0%,#559c00 100%); /* Chrome10-25,Safari5.1-6 */
                        background: linear-gradient(135deg, #8fc400 0%,#559c00 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8fc400', endColorstr='#559c00',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
                    }
                }
                .rounde {
                    text-align: center;
                    margin-bottom: 5px;
                    margin-top: 15px;
                }
                .t-a{
                    padding-top: 10px;
                    padding-bottom: 5px;
                    border-top: 1px dashed rgba(255,255,255,.3);
                    border-bottom: 1px dashed rgba(255,255,255,.3);
                    margin-bottom: 20px;
                    big{
                        margin-left: 30px;
                        margin-right: 30px;
                        font-size: 14px;
                        display: inline-block;
                        span{
                            display: block;
                            font-size: 32px;
                            font-weight: bold;
                        }
                    }

                }
                .rounde a {
                    margin-left: 10px;
                    margin-right: 10px;
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    display: inline-block;
                    color: white;
                    border-radius: 40px;
                    border:1px solid white;
                    text-align: center;
                    font-size: 20px;
                    font-weight: bold;
                    background-color: white;
                    color: #8f4ec6;
                    margin-bottom: 15px;
                    cursor: pointer;
                    &:hover,&.active{
                        background-color:#8f4ec6;
                        color: white;
                    }

                    small{
                        display: block;
                    }
                }
                .splatky{
                    margin-top: 20px;
                    a{
                        line-height: 20px;
                        padding-top: 10px;
                        margin-bottom: 5px;
                        small{
                            font-size: 13px;
                            font-weight: normal;
                        }
                    }
                }
                .text-cal{
                    border-left: 1px dashed white;
                    text-align: right;
                    big{
                        display: block;
                        font-weight: bold;
                        font-size: 22px;
                    }
                    small{
                        display: block;
                    }
                }
            }
            &#rezervace{
                overflow-y: auto;
                padding-top: 15px;
                padding-bottom: 15px;
                background-color: #84ce2c;
                .checkbox{
                    padding-bottom: 15px;
                    line-height: 18px;
                    font-size: 14px;
                    label{
                        color: white;
                        font-size: 14px;
                    }
                    .checkbox-input{
                        margin-top: 5px;
                        margin-right: 15px;
                    }
                }
            }
        }
        ul{
            li{
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 15px;
                background-color: white;
                //height: 20px;
                line-height: 20px;
                &:nth-child(odd) {
                    background: #e9e9e9;
                }
            }
            &.second{
                li{
                    background-color: #e9e9e9;
                    &:nth-child(odd) {
                        background: white;
                    }
                }
            }
        }
        .owl-galery{
            background-color: #03c7fb;
            width: 100%;
            padding-left: 80px;
            padding-right: 80px;
            .item{
                a.photo{
                        span{
                            display: block;
                            position: absolute;
                            z-index: 999;
                            height: 300px;
                            line-height: 320px;
                            color: white;
                            text-align: center;
                            background-color: rgba(23,166,225,.1);
                            transition: background-color 0.5s ease;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            i{
                                opacity: 0;
                                font-size: 70px;
                                transition: opacity 0.5s ease;

                            }
                        }
                        &:hover span{
                            background-color: rgba(23,166,225,.8);
                            i{
                                opacity: 1;
                            }
                        }
                }
            }
            .owl-nav{
                .owl-prev,.owl-next{
                    position: absolute;
                    font-size: 40px;
                    text-align: center;
                    width: 80px;
                    height: 340px;

                    margin-top: -340px;
                    line-height: 340px;
                    color: white;
                }
                .owl-prev{
                    left: -80px;
                    &.disabled{
                        opacity: .3;
                    }
                }
                .owl-next{
                    right: -80px;
                    &.disabled{
                        opacity: .3;
                    }
                }
            }
        }
    }
    /* END detail car */
    /* START about */
    #about{
        background-image: url("../images/aboutBg@1900.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        color: white;
        font-size: 22px;
        padding-top: 20px;
        padding-bottom: 20px;
        .text-center{
            margin-bottom: 20px;
        }
        i{
            display: block;
            font-size:80px;
            margin-bottom: 20px;
            margin-top: 15px;
        }
        a{
            color: white;
        }
    }
    /* END about*/
    /* START why options */
    #why{
        background-color: #03c7fb;
        .icon,.photos {
            min-height: 330px;
        }
        .icon{
            background-color: #f28120;
            font-size: 100px;
            line-height: 310px;
            color: white;
        }
        .text{
            color: white;
            background-color: #03c7fb;
            padding-top: 70px;
            padding-bottom: 20px;
            h2{
                margin: 0px;
                padding: 0px;
                background: none;
                font-size: 33px;
                padding-bottom: 20px;
            }
            ul{
                li{
                    font-size: 16px;
                    margin-bottom: 10px;
                    &::before{
                        content: "\e900";
                         font-family: 'iconpack' !important;
                         color: white;
                         margin-top: 3px;
                         padding-right: 10px;
                    }
                }
            }
        }
        .photos{
            background-image: url("../images/photo-bg.jpg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
    /* END why options */
    /* START map options */
    .contact-box{
        min-height: 423px;
        .checkbox{
            padding-bottom: 15px;
            line-height: 18px;
            font-size: 14px;
            label{
                font-size: 14px;
            }
            .checkbox-input{
                margin-top: 5px;
            }
        }
    }
    #map{
        #googleMap{
            height: 423px;
        }
        a.photo{
                span{
                    display: block;
                    position: absolute;
                    z-index: 999;
                    height: 300px;
                    color: white;
                    text-align: center;
                    background-color: rgba(23,166,225,.1);
                    transition: background-color 0.5s ease;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i{
                        opacity: 0;
                        font-size: 70px;
                        transition: opacity 0.5s ease;
                    }
                }
                &:hover span{
                    background-color: rgba(23,166,225,.8);
                    i{
                        opacity: 1;
                    }
                }
        }
        .contact-box{
            background-color: #008bc6;
            color: white;
            padding-top: 20px;
            padding-bottom: 20px;
            big{
                line-height: 40px;
                font-size: 30px;
                display: block;

            }
            small{
                font-size: 18px;
                text-transform: uppercase;
                display: block;
                margin-bottom: 20px;
            }
            .form-group{
                input,textarea{
                    width: 100%;
                    margin-bottom: 15px;
                    padding: 5px;
                    font-size: 14px;
                    color: black;
                }
                textarea{
                    height: 100px;
                }
            }
            .submit{
                label{
                    margin-top: 25px;
                    display: block;
                    line-height: 18px !important;
                    input{
                        margin-right: 15px;
                    }
                }
                button{
                    line-height: 50px;
                    background-color: #f28120;
                    border-bottom: 2px solid #c86c1e;
                }
            }

        }
    }
    /* END map options */
    /* START footer */
    #footer {
        background-color: white;
        .col{
            background-image: url("../images/footerBg.svg");
            background-position: right center;
            background-size: auto 100%;
            background-repeat: no-repeat;
            padding-top: 20px;
            padding-bottom: 15px;
            padding-right: 40px;
            min-height: 193px;
            &:last-child {
                background: none;
            }
            &.text{
                font-size:13px;
                a{
                    color: black;
                }
            }
            .logos{
                img{
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    margin-top: 20px;
                    margin-bottom: 10px;
                }
            }
            &.log{
                img{
                    max-width: 150px;
                    margin-left: auto;
                    margin-right: auto;
                }
                p{
                    margin-left: 20px;
                    strong{
                        margin-left: 10px;
                    }
                }

            }
            .fb-link{
                font-size: 40px;
                line-height: 163px;
            }
            .logo{
                padding-top: 41px;
                display: block;
            }
        }

    }
    /* END footer */
    /* START footer copywrite */
    #footer-copywrite{
        color: white;
        line-height: 72px;
        font-size: 14px;
        a{
            color: #dd13b9;
            padding-bottom: 5px;
            border-bottom: 2px solid #dd13b9;
            &:hover{
                color: #dd13b9;
                border-bottom: 2px solid #303030;
            }
        }
    }
    /* END footer copywrite */
    form.error{
        .form-group.error,.form-group.warning{
            input{
                border:1px solid red;
            }
            textarea{
                border:1px solid red;
            }
        }
        .icheckbox_minimal{
            border: 1px solid red;
        }
        .submit.error{
            label{
                border-bottom: 1px dashed red;
                padding-bottom: 5px;
            }
        }
        p.help-block{
            margin: 0px;
            padding: 0px;
            padding-bottom: 15px;
            ul{
                list-style: none;
                margin: 0px;
                padding: 0px;
                li{
                    line-height: 12px !important;
                    padding: 0px;
                    margin: 0px;
                    background: none !important;
                    font-size: 13px;
                }
            }
        }
    }
}
.owl-galery{
    a.photo{
        display: block;
        overflow: hidden;
    }
}


/* FIX for mobile */
@media screen and (max-width:1199px) {
    #head{
        img{
            max-width: 150px !important;
            margin-top: 15px;
            margin-bottom: 15px;
        }
        .pull-right{
            img{
                display: none;
            }
        }
    }
    #headline{
        ul{
            li:last-child{
                display: none;
            }
        }
    }
    #headlinesub{
        big{
            font-size: 23px !important;
        }

    }
    ul.products{
        strong{
            padding-top: 0px !important;
            font-size: 22px !important;
        }
        .icons{
            i{
                font-size: 20px !important;
            }
            span{
                font-size: 11px !important;
            }
        }
        .h-2{
            height: 79px !important;
        }
    }
    #detail-car{
        .price{
            font-size: 20px !important;
            padding-left: 10px !important;
            big{
                /*font-size: 30px!important;*/
            }
        }
        ul{
            li{
                font-size: 13px !important;
                padding-top: 5px;
                padding-bottom: 5px;
                line-height: 14px;
            }
        }
    }
    #filter{
        &.subpage{
            .checkbox{
                label{
                    line-height: 30px !important;
                }

            }
        }
    }
}
@media screen and (max-width:1060px) {
    #detail-car{
        ul{
            li{
                font-size: 12px !important;
            }
        }
    }
    #why{
        .text{
            padding-top: 30px !important;
        }
    }
}
@media screen and (max-width:991px) {
    #headlinesub{
        padding-top: 10px;
        big{
            font-size: 30px !important;
            line-height: 34px !important;
        }
        .btn-group{
            text-align: center;
        }

    }
    #headlinesub{
        big{
            font-size: 30px !important;
            padding-bottom: 10px;
        }
        span{
            font-size: 18px !important;
        }
    }
    #filter{
        .btn-filter{
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        &.subpage{
            .btn-filter{
                text-align: right;
            }
            .btn2,button{
                min-width: 100px !important;
                margin-left: auto;
                margin-right: 0px;
            }
        }
    }
    .btns{
        a{
            margin-left: 3px;
            margin-right: 3px;
            padding: 10px;
        }
    }
    #head{
        padding-bottom: 0px !important;
        .pull-right{
            width: 100%;
            position: relative;
            margin-top: -80px;
            li.dropdown:hover::before{
                content: "" !important;
                display: none !important;
            }
            ul{
                display: none;
                background-color: #18a5df;
                border-bottom: 6px solid #f58220;
                left: 0px;
                margin: 0px;
                li{
                    &:hover a{
                        border-bottom: 1px solid white;
                    }
                }
                &.active{
                    display: block;
                    width: 100%;
                    clear: both;
                    position: static;
                    left:-15px;
                    background-color: white;
                    z-index: 999;
                    margin-top: 70px;

                    li{
                        background-color: #18a5df;
                        display: block;
                        text-align: left;
                        padding-left: 30px;

                        a{
                            color: white;
                            line-height: 46px;
                        }
                        &:hover ul.dropdown{
                            display: none;
                        }
                        &.active a{
                            border: none !important;
                        }
                    }
                    li{
                        &::after{
                            display: none !important;
                        }
                        &::before{
                            display: none !important;
                        }
                    }
                }

                li.open{
                    color: white;
                    background-color: #5ebee8;
                    padding: 0px;
                    a{
                        color: white;
                        padding-left: 30px;
                    }
                    ul{
                        background-color: #5ebee8;
                        border-radius: 0px;
                        border-bottom: none;
                        li{
                            background-color: #5ebee8;
                            padding-left: 30px;
                            a{
                                padding-left: 0px;
                                border-bottom: 1px solid #5ebee8;
                                &::before{
                                    content: "\e915";
                                    color: white;
                                    font-family: iconpack!important;
                                    font-size: 10px;
                                    padding-right: 10px;
                                }
                            }
                            &:hover a{
                                border-bottom: 1px solid white;
                            }
                        }
                    }
                    &::after{
                        display: none !important;
                    }
                    &::before{
                        display: none !important;
                    }
                }
                ul.dropdown{
                    border: none;
                    background: none;
                    -webkit-box-shadow:none;
                    box-shadow: none;
                    width: 100% !important;
                    position: relative !important;
                    margin-top: 0px;
                    padding-top: 0px;
                    z-index: 1000;
                    li{
                        padding: 0px;
                        padding-left: 20px;
                        display: block;
                        &:before{
                            content: "\e915";
                            padding-right: 10px;
                            font-size: 12px;
                            color: white;
                            font-family: iconpack!important;
                        }
                    }
                }
                li.dropdown.open{
                    ul.dropdown{
                        display: block;
                    }
                }
            }
            i.icon{
                position: absolute;
                right: 15px;
                cursor: pointer;
                display: block;
                color: #18a5df;
                font-size: 30px;
                margin-top: 5px;
                margin-right: 10px;
            }
        }
    }
    #headline{
        .hidden-xs{
            display: none;
        }
    }
    ul.products{
        strong{
            text-align: left;
        }
        .icons{
            text-align: center;
            span{
                float: none!important;
                display: inline-block !important;
                padding-right: 7px !important;
                padding-left: 7px !important;
            }
        }
        .h-2{
            float: left;
            width: 50% !important;
            height: 60px;

        }
    }
    #detail-car{
        ul{
            li{
                font-size: 16px !important;
                padding-top: 15px;
                padding-bottom: 15px;
                line-height: normal !important;
            }
        }
    }
    #why{
        .text{
            padding-top: 30px !important;

            ul{
                li{
                    font-size: 14px !important;
                }
            }
        }
    }
}
@media screen and (max-width:990px) {
    #headlinesub{
        big{
            display: block !important;
        }
    }
    #car-scroll{
        .container{
            width: 100%;
            padding: 0px !important;
        }
        .row{
            padding: 0px !important;
        }
        .pull-right{
            padding-top: 15px !important;
            small{
                display: none !important;
            }

            a{
                margin-right: 5px;
                text-align: center;
                width: 200px;
                display: block !important;
                height: auto;
                word-wrap: normal !important;
            }
        }
    }
}

@media screen and (max-width:760px) {
    #detail-car{
        .price{
            font-size: 20px !important;
            text-align: center;
            big{
                font-size: 30px!important;
            }
        }
        .bt{
            a{
                padding-top: 25px!important;
                padding-bottom: 25px !important;
            }
        }
    }
    #map{
        .form-group{
            padding-left: 0px;
        }
    }
    #footer-copywrite{
        p{
            text-align: center;
            line-height: 24px;
        }
    }
    ul.products{
        li{
            strong{
                text-align: center;
            }
        }
    }
}
@media screen and (min-width:990px) {
    .visible-from-sm{
        display: none;
    }
}
@media  (min-width:993px) and  (max-width:1100px){
    /*.col-md-5ths:nth-child(4){
        display: none;
    }
    */
    .col-md-5ths{
         width: (100% / 4) !important;
    }
    .col-md-5ths:nth-child(5){
        width: 100% !important;
        text-align: center;
    }
    button.btn{
        margin-top: 0px !important;
        margin-bottom: 10px;
    }
    .visible-from-sm{
        display: none;
    }
    #detail-car{
        .col-md-5{
            a.photo{
                overflow:hidden;
                display: block;
                width: 100%;
                img{
                    min-width: 543px !important;
                    min-height: 542px !important;
                }
            }
        }
    }
    #detail-car{
        .detail-car-photo,.detail-car-desc{
            width: 100%;
        }
    }
}


@media screen and (max-width:745px) {
    #footer{
        text-align: center;
        .col{
            background: none !important;
        }
    }
    .contact-box{
        .submit{
            padding-top: 20px;
            text-align: center !important;
        }
        big,small{
            text-align: center;
        }
    }

}
@media screen and (max-width:586px) {
    #headlinesub{
        line-height: 40px !important;
        big{
            font-size: 30px !important;
            display: block;
            width: 100%;
            text-align: center;
        }
        span{
            display: block;
            text-align: center;
            width: 100%;
            float: none !important;
            font-size: 18px !important;
        }
    }
    #headlinesub{
        .col-md-12{
            text-align: center;
        }
        a.pull-right.link{
            float: none !important;
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }

}
@media screen and (max-width:400px) {
    #headlinesub{
        a.pull-right.link{
            display: block;
            margin-bottom: 15px;
        }
    }
}
@media screen and (max-width:369px) {
    #footer{
        text-align: center;
        .col{
            background: none !important;
        }
    }
    #about{
        .col-xs-6{
            width: 100%;
        }
    }
    #filter{
        .col-xs-6{
            width: 100%;
        }
        a.btn2{
            width: 100% !important;
            margin-bottom:10px;
        }
        .btn-filter{
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
            button{
                width: 100%;
            }
        }
    }
}

@media screen and (max-width:414px) {
    #filter{
        .col-xs-6{
            width: 100%;
        }
        a.btn2{
            width: 100% !important;
            margin-bottom:10px;
        }
        .btn-filter{
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
            button{
                width: 100%;
            }
        }
    }
}

@media screen and (max-width:990px) {
    .btn-filter{
        padding-top: 10px;
        text-align: center !important;
    }
}
@media screen and (min-width:1000px) {
    .btn-filter{
        text-align: right !important;
    }
}



@media screen and (max-width: 330px) {
    #headline{
        ul{
            li{
                font-size: 10px !important;
            }
        }
    }
    #filter{
        .text-center{
            a{
                font-size: 12px;
            }
        }
    }
}
@media screen and (max-width: $breakpoint-tablet) {

}
/* FIX for small desktop */
@media screen and (max-width: $breakpoint-small-desktop) {

}

@media screen and (max-width:375px) {

}
@media screen and (max-width:700px) {
    .submit{
        label{
            width: 100%;
            padding-bottom: 20px;
            position: relative;
        }
        .btn{
            width: 100%;
            float: none;
            position: relative;
            margin-top: 60px;
        }
    }

}
@media screen and (max-width: 959px) {
    .visibles-md{
        display: none !important;
    }
}
@media screen and (min-width:980px) {
    .visibles-md {
        display: block
    }

    .visibles-sm {
        display: none
    }
}

@media screen and (max-width:980px) {
    .visibles-md {
        display: none !important
    }

    .visibles-sm {
        display: block !important
    }
}
.modal{
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    min-height: 100vh;
    position: absolute;
    z-index: 999;
    top: 0px;
    display: none;
    &.active{
        display: block;
    }
    .modal-content{
        margin-top: 80px;
        background-color: white;
        color: white;
        padding-bottom: 15px;
        .alert{
            padding: 25px;
            font-size: 20px;
        }
        .modal-head{
            border-bottom: 1px solid rgba(255,255,255,.2);
            h4{
                font-size: 30px;
                font-weight: bold;
                margin-top: 20px;
                margin-bottom: 20px;
            }
            a{
                cursor: pointer;
                color: white;
                text-decoration: none;
                &:hover{
                    text-decoration: none;
                }
            }
        }
        input[type="text"],input[type="email"]{
            width: 100%;
            margin-bottom: 15px;
            padding: 5px;
            font-size: 14px;
            color: #000
        }
        textarea{
            border: none;
            height: 100px;
            width: 100%;
            margin-bottom: 15px;
            padding: 5px;
            font-size: 14px;
            color: #000
        }
        .submit{
            line-height: 42px;

        }
        .checkbox{
            padding-bottom: 15px;
            line-height: 18px;
            font-size: 14px;
            label{
                font-size: 14px;
            }
            .checkbox-input{
                margin-top: 5px;
                margin-right: 15px;
            }
        }
    }
    &#modal-rezervace{
        .modal-content{
            background-color: #84ce2c;
        }
        button{
            width: 100%;
            font-size: 15px;
        }
        .title-car{
            margin-top: 15px;
            padding: 10px;
            background-color: white;
            color: #84ce2c;
        }
    }
    &#modal-kalkulace-splatek{
        .title-car{
            margin-top: 15px;
            padding: 10px;
            background-color: white;
            color: #8f4ec6;
        }
        .ak{
            opacity: .6;
        }
        .text-akontace{
                font-size: 23px !important;
        }
        .rangeInput#akontace{
            padding-left: 0px !important;
            border-right: 1px solid rgba(255, 255, 255, .3);
        }
        .modal-content{
            background-color: #8f4ec6;
            .t-a{
                padding-top: 15px;
                padding-bottom: 10px;
                border-top: 1px solid rgba(255, 255, 255, .3);
                border-bottom: 1px solid rgba(255, 255, 255, .3);
                margin-bottom: 20px;
                big{
                    margin-left: 30px;
                    margin-right: 30px;
                    font-size: 14px;
                    display: inline-block;
                    span{
                        display: block;
                        font-size: 32px;
                        font-weight: 700;
                    }
                }
            }
            .rounde a{
                margin-left: 10px;
                margin-right: 10px;
                width: 60px;
                height: 60px;
                line-height: 60px;
                display: inline-block;
                color: #fff;
                border-radius: 40px;
                border: 1px solid #fff;
                text-align: center;
                font-size: 20px;
                font-weight: 700;
                background-color: #fff;
                color: #8f4ec6;
                margin-bottom: 15px;
                cursor: pointer;
                &:hover,&.active{
                    background-color: #8f4ec6;
                    color: #fff;
                }
            }
            .splatky{
                margin-bottom: 10px;
                a{
                    line-height: 20px;
                    padding-top: 10px;
                    margin-bottom: 5px;
                    small{
                        font-weight: 100;
                        display: block;
                        font-size: 13px;
                    }
                }
            }
            button{
                width: 100%;
                font-size: 15px;
                background-color: #84ce2c;
                border-bottom: 6px solid #5c9617
            }

        }
    }
    /* START modal options */
    &#modal-image{
        background-color: rgba(0,0,0,0.7);
        width: 100%;
        min-height: 100vh;
        position: absolute;
        z-index: 999;
        top: 0px;
        display: none;
        color: black;
        padding-top: 20px;
        &.active{
            display: block;
        }
        .modal-body{
            background-color: white;
            padding-bottom: 15px;
            form{
                input[type="text"],input[type="email"],textarea{
                    background-color: white;
                    font-size: 12px;
                    line-height: 38px;
                    color: #000;
                    border: 1px solid rgba(0,0,0,.3);
                }
                .error{
                    input[type="text"],input[type="email"],textarea{
                        border: 1px solid red;
                    }
                }
            }
        }
        .modal-head{
            h4{
                padding: 0px;
                margin: 0px;
                padding-top: 20px;
                padding-bottom: 15px;
            }
        }
        a.close-modal{
            font-size: 20px;
            margin-top: 25px;
            &:hover{
                color: #bd0007;
            }
        }

    }
    /* END modal options */
}
@media screen and (max-width: 1095px) {
    .desktop{
        display: none !important;
    }
    .mobile{
        display: block;
    }
}
@media screen and (min-width: 1096px) {
    .desktop{
        display: block;
    }
    .mobile{
        display: none !important;
    }
}

@media screen and (max-width: 450px) {
    #detail-car{
        .list#kalkulacka-splatek{
            .t-a{
                big{
                    margin-left: 5px !important;
                    margin-right: 5px !important;
                    span{
                        font-size: 20px !important;
                    }
                }
            }
        }
    }
    .visibleMobile{
        display: block !important;
    }
    #headlinesub{
        padding-bottom: 10px;
        a{
            display: block !important;
        }
    }
    a.products{
        .desc{
            .icons{
                padding-right: 0px !important;
                span{
                    font-size: 18px !important;
                }
            }
        }
    }
}
@media screen and (max-width: 414px) {
    a.products{
        .desc{
            strong{
                font-size: 22px;
                margin-bottom: 30px;
                text-align: center;
            }
            .icons{

                padding: 0px !important;
                span{
                    padding-right: 0px !important;
                    width: (100% / 4) !important;
                    font-size: 14px !important;
                }
            }
        }
    }
}

.row.display-flex {
    display: flex;
    flex-wrap: wrap;
    &::before{
        content: "";
        display: none;
    }
}
.row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
    float: none;
}
.modal{
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    min-height: 100vh;
    position: absolute;
    z-index: 999;
    top: 0px;
    display: none;
    color: black;
    padding-top: 20px;
    &#modal-image{
        .modal-body{
            background: none !important;
        }
    }
    &.active{
        display: block;
    }
    .modal-body{
        margin-top: 100px;
        padding-bottom: 15px;
    }
    a.close-modal{
        position: absolute;
        right: 30px;
        z-index: 999;
        font-size: 20px;
        margin-top: 15px;
        width: 50px;
        height: 50px;
        line-height: 52px;
        border-radius: 50px;
        background-color: white;
        text-align: center;
        display: block;
        cursor: pointer;
        &:hover{
            color: #f58220 !important;
        }
    }

}


@import "./libs/bootstrap-datetimepicker.min.css";
